<template>
    <div class="input_wrapper" v-if="show">
        <div class="input_label">{{property.name}}</div>
        <div class="input_line"></div>
        <div class="input_input">
            <el-upload
                    class="cover-upload"
                    :action="fileUpUrl"
                    :http-request="upFile"
                    :file-list="imgSrcList"
                    :on-remove="handleRemove"
                    :before-upload="checkFile">
                <el-button class="input-btn" type="text">点击上传</el-button>
            </el-upload>
        </div>
    </div>
</template>

<script>
import surveyUtil from "@/common/surveyUtil";

export default {
    name: "YtFileInput",
    components: {},
    data() {
        return {
            imgSrcList: this.value?this.value:[],
            maxCnt: this.property.max?this.property.max:9,
            show: !this.property.hidden
        }
    },
    props: {
        property: {
            type: Object,
            required: true
        },
        value: {
            required: false
        },
        propertyIndex:{
            type: Number,
            required: false
        }
    },
    watch: {
        value(val) {
            this.imgSrcList = val?val:[];
        },
        imgSrcList(val) {
            this.$emit('update:value', val)
        }
    },
    computed:{
        fileUpUrl: function () {
            return this.$urlConstant.cms.articleFilePost
        },
    },
    created() {
        this.$globalEvent.$on('selectChanged', (property)=>{
            // console.log('selectChanged on')
            setTimeout(()=>{
                const show = surveyUtil.showProperty(this.property, property)
                if (show === 1) {
                    this.show = true
                    this.property.propertyData.hidden = !this.show
                }
                if (show === 2) {
                    this.show = false
                    this.property.propertyData.hidden = !this.show
                }
            },100)

        })
    },
    methods: {
        upFile(req) {
            console.log(req)
            let file = req.file
            let formData = new FormData();
            formData.append('name', file.name);
            formData.append('file', file);
            this.$httpUtil.postForm(this.$urlConstant.survey.surveyUpfile, formData, (responseData) => {
                console.log('responseData:' + JSON.stringify(responseData));
                this.imgSrcList.push({name:responseData.data.fileName,url:responseData.data.fullName})
                this.$message({
                    message: '上传成功',
                    type: 'success'
                });

            }, this);
        },
        handleRemove(file, fileList) {
            console.log(file, fileList)
            this.imgSrcList = fileList
        },
        checkFile(file) {
            console.log(file.size)
            if (file.size > 5 * 1024 * 1024) {
                this.$message.error("文件大小不能超过5m")
                return false
            }
            return true
        }
    }
}
</script>

<style scoped>
.input_wrapper {
    display: flex;
    background: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    min-height: 30px;
    font-size: 12px;
    align-items: center;
    font-family: Helvetica;
    color: #1B243F;
    letter-spacing: 0.89px;
    text-align: center;
}
.input_label {
    padding-left: 10px;
    width: 87px;
    line-height: 16px;
}
.input_line {
    height: 23px;
    width: 1px;
    background: #656D72;
    margin: 0 5px;
}
.input_input,.input_inner_input {
    height: 100%;
    flex: 1;
    border: unset;
    background-color: transparent;
}
.cover-upload, .cover-upload >>> .el-upload{
    width: 100%;
    min-height: 30px;
}
.input-btn {
    width: 100%;
    font-size: 12px;
    color: #1B243F;
}
</style>