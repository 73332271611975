<template>
	<div>
		<div class="ss_label">{{propertyIndex + 1}}.{{property.name}}</div>
		<div class="ss_desc">{{property.description?property.description:''}}</div>
		<div class="ss_ss_wrapper">
			<radio-group class="ss_radio_group" @change="radioChange" :disabled="property.ro || property.propertyData.immutable">
				<label class="ss_radio_label" v-for="(item, index) in property.options" :key="index">
					<div class="ss_radio_v">
						<radio :value="item.id" :checked="item.id === val" />
					</div>
					<div>{{item.name}}</div>
				</label>
			</radio-group>
		</div>
	</div>
</template>

<script>
	import surveyUtil from "@/common/surveyUtil";

    export default {
		name: '',
		components: {},
		data() {
			return {
				val: this.value,
			}
		},
		props: {
			property: {
				type: Object,
				required: true
			},
			value: {
				required: false
			},
			propertyIndex: {
				type: Number,
				required: true
			},
			styles: {
				type: Object,
				required: false,
				default() {
					return {
						height: '40px',
						fontSize: '14px'
					}
				}
			}
		},
		watch: {
			value(val) {
				this.val = val;
			},
			val(val) {
				this.$emit('update:value', val)
			}
		},
        mounted() {
            this.doDataChangedAction()
        },
		methods: {
			radioChange(evt) {
				this.val = evt.target.value
                this.doDataChangedAction()
			},
            doDataChangedAction() {
                surveyUtil.doDataChangedAction(this.property, this.val)
            }
		}
	}
</script>

<style scoped>
	.ss_label {
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #000000;
		letter-spacing: 0;
		line-height: 25px;
	}

	.ss_desc {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #666666;
		letter-spacing: 0;
		line-height: 25px;
	}

	.ss_ss_wrapper {
	}

	.ss_radio_group {
		display: flex;
		flex-wrap: wrap;
	}
	.ss_radio_label {
		width: 44%;
		display: flex;
		padding: 5px 10px;
	}
	.ss_radio_v {
		margin-right: 10px;
	}
</style>
