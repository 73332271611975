<template>
	<div>
		<YtMultiSelectDefault v-if="property.displayType === surveyPropertyMultiDisplayType.normal"
			:property="property" :value.sync="val" :propertyIndex="propertyIndex"></YtMultiSelectDefault>
		<YtMultiSelectDrop v-if="property.displayType === surveyPropertyMultiDisplayType.drop" :property="property"
			:value.sync="val" :otherValue.sync="otherVal" :propertyIndex="propertyIndex"></YtMultiSelectDrop>
	</div>
</template>

<script>
	import YtMultiSelectDefault from './YtMultiSelectDefault.vue'
	import YtMultiSelectDrop from './YtMultiSelectDrop.vue'
	export default {
		name: '',
		components: {
			YtMultiSelectDefault,
            YtMultiSelectDrop
		},
		data() {
			return {
				val: this.value,
                otherVal: this.otherValue
			}
		},
		props: {
			property: {
				type: Object,
				required: true
			},
			value: {
				required: false
			},
            otherValue: {
                required: false
            },
			propertyIndex: {
				type: Number,
				required: false
			}
		},
		computed: {
			surveyPropertyMultiDisplayType() {
				return this.$constant.surveyPropertyMultiDisplayType
			},
		},
		watch: {
			value(val) {
				console.log('val change:' + val)
				this.val = val;
			},
			val(val) {
				console.log('emit value 1:' + val)
				this.$emit('update:value', val)
			},
            otherValue(val) {
                console.log('val change:' + val)
                this.otherVal = val;
            },
            otherVal(val) {
                console.log('emit value 1:' + val)
                this.$emit('update:otherValue', val)
            }
		}
	}
</script>

<style scoped>

</style>
