<template>
	<div>
		<YtSingleSelectDefault v-if="property.displayType === surveyPropertySingleDisplayType.normal" :property="property"
			:value.sync="val" :propertyIndex="propertyIndex"></YtSingleSelectDefault>
		<YtSingleSelectDrop v-if="property.displayType === surveyPropertySingleDisplayType.drop" :property="property"
			:value.sync="val" :otherValue.sync="otherVal" :propertyIndex="propertyIndex"></YtSingleSelectDrop>
		<YtSingleSelectBoolean v-if="property.displayType === surveyPropertySingleDisplayType.boolean" :property="property"
			:value.sync="val" :otherValue.sync="otherVal" :propertyIndex="propertyIndex"></YtSingleSelectBoolean>
    </div>
</template>

<script>
	import YtSingleSelectDefault from './YtSingleSelectDefault.vue'
	import YtSingleSelectDrop from './YtSingleSelectDrop.vue'
    import YtSingleSelectBoolean from "@/components/survey/components/properties/singleSelect/YtSingleSelectBoolean";
	export default {
		name: '',
		components: {
            YtSingleSelectBoolean,
			YtSingleSelectDefault,
			YtSingleSelectDrop
		},
		data() {
			return {
				val: this.value,
				otherVal: this.otherValue,
			}
		},
		props: {
			property: {
				type: Object,
				required: true
			},
			value: {
				required: false
			},
			otherValue: {
				required: false
			},
			propertyIndex: {
				type: Number,
				required: true
			}
		},
		computed: {
			surveyPropertySingleDisplayType() {
				return this.$constant.surveyPropertySingleDisplayType
			},
		},
		watch: {
			value(val) {
				console.log('val change:' + val)
				this.val = val;
			},
			val(val) {
				console.log('emit value 1:' + val)
				this.$emit('update:value', val)
			},
			otherValue(val) {
				console.log('val change:' + val)
				this.otherVal = val;
			},
			otherVal(val) {
				console.log('emit value 1:' + val)
				this.$emit('update:otherValue', val)
			}
		},
        
        mounted() {},
        
		methods: {}
	}
</script>

<style scoped>
	
</style>
