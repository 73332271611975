<template>
    <div class="su-wrapper">
        <nh-title :title="title?title:survey.name"></nh-title>
        <div class="sc-content">
            <div v-if="patientId" class="sc-patient">
                <PatientInfo :patient-id="patientId"></PatientInfo>
            </div>
            <div class="su-container">
                <div v-if="showTitle" class="title_wrapper">
                    <div class="title_label">{{ title ? title : survey.name }}</div>
                    <div class="title_desc">{{ survey.description ? survey.description : '' }}</div>
                </div>
                <div class="survey_content" v-if="surveyNormalProperties">
                    <div class="survey_content_line" v-for="(property,index) in surveyNormalProperties" :key="index">
                        <YtSingleSelect v-if="property.type === surveyPropertyType.singleSelect" :property="property"
                                        :value.sync="property.propertyData.single"
                                        :otherValue.sync="property.propertyData.content"
                                        :propertyIndex="index"></YtSingleSelect>
                        <YtMultiSelect v-if="property.type === surveyPropertyType.multiSelect" :property="property"
                                       :value.sync="property.propertyData.multi"
                                       :otherValue.sync="property.propertyData.content"
                                       :propertyIndex="index"></YtMultiSelect>
                        <YtInput v-if="property.type === surveyPropertyType.text" :property="property" :surveyDataId="surveyDataId"
                                 :value.sync="property.propertyData.content" :propertyIndex="index"></YtInput>
                        <YtTextarea v-if="property.type === surveyPropertyType.textarea" :property="property"
                                    :value.sync="property.propertyData.content" :propertyIndex="index"></YtTextarea>
                        <YtImageInput v-if="property.type === surveyPropertyType.image" :property="property"
                                      :value.sync="property.propertyData.imgUrls" :propertyIndex="index"></YtImageInput>
                        <YtFileInput v-if="property.type === surveyPropertyType.file" :property="property"
                                     :value.sync="property.propertyData.fileUrls" :propertyIndex="index"></YtFileInput>
                        <YtDateSelect v-if="property.type === surveyPropertyType.date" :property="property"
                                      :value.sync="property.propertyData.content" :propertyIndex="index"></YtDateSelect>
                        <TipView :tip="property.tip"></TipView>
                    </div>
                    <div class="save-btn-wrapper">
                        <nh-button class="a-bottom" :showPhoneBtn="showPhoneBtn"
                                   @click="saveData" @make-phone-call="makePhoneCall">保存</nh-button>
                    </div>
                </div>
                <div class="survey_popup" v-if="showPopup">
                    <div class="survey_popup_wrapper">
                        <div class="survey_popup_line" v-for="(property,index) in surveyPreProperties" :key="index">
                            <YtSingleSelect v-if="property.type === surveyPropertyType.singleSelect" :property="property"
                                            :value.sync="property.propertyData.single" :propertyIndex="index"
                                            :otherValue.sync="property.propertyData.content"></YtSingleSelect>
                            <YtMultiSelect v-if="property.type === surveyPropertyType.multiSelect" :property="property"
                                           :value.sync="property.propertyData.multi" :propertyIndex="index"
                                           :otherValue.sync="property.propertyData.content"></YtMultiSelect>
                            <YtInput v-if="property.type === surveyPropertyType.text" :property="property" :surveyDataId="surveyDataId"
                                     :value.sync="property.propertyData.content" :propertyIndex="index"></YtInput>
                            <YtTextarea v-if="property.type === surveyPropertyType.textarea" :property="property"
                                        :value.sync="property.propertyData.content" :propertyIndex="index"></YtTextarea>
                            <YtImageInput v-if="property.type === surveyPropertyType.image" :property="property"
                                          :value.sync="property.propertyData.imgUrls" :propertyIndex="index"></YtImageInput>
                            <YtFileInput v-if="property.type === surveyPropertyType.file" :property="property"
                                         :value.sync="property.propertyData.fileUrls" :propertyIndex="index"></YtFileInput>
                            <YtDateSelect v-if="property.type === surveyPropertyType.date" :property="property"
                                          :value.sync="property.propertyData.content" :propertyIndex="index"></YtDateSelect>
                        </div>
                        <div class="pre_button_wrapper">
                            <button class="pre_button sc_button" :style="'background-color:' + btnColor" @click="preClick">
                                确定
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import YtTextarea from './properties/YtTextarea.vue'
import YtSingleSelect from './properties/singleSelect/YtSingleSelect.vue'
import YtMultiSelect from './properties/multiSelect/YtMultiSelect.vue'
import YtInput from './properties/YtInput.vue'
import YtImageInput from './properties/imageInput/YtImageInput.vue'
import YtDateSelect from './properties/YtDateSelect.vue'
import StringUtil from "@/common/utils/StringUtil";
import NhTitle from "@/components/patient/components/nh-title";
import NhButton from "@/components/patient/components/nh-button";
import YtFileInput from "@/components/survey/components/properties/fileInput/YtFileInput";
import surveyUtil from "@/common/surveyUtil";
import PatientInfo from "@/components/patient/components/patient/PatientInfo";
import TipView from "@/components/survey/components/properties/TipView";

export default {
    name: 'SurveyContent',
    components: {
        TipView,
        PatientInfo,
        YtFileInput,
        NhButton,
        NhTitle,
        YtTextarea,
        YtSingleSelect,
        YtMultiSelect,
        YtInput,
        YtImageInput,
        YtDateSelect,
    },
    data() {
        return {
            surveyData: {},
            surveyNormalProperties: [],
            surveyPreProperties: [],
            showPopup: false,
            doCleanCachedData: false,
            styles: {
                height: '40px',
                fontSize: '14px'
            },
            repeatToken: undefined
        }
    },
    props: {
        survey: {
            type: Object,
            required: true
        },
        surveyDataId: {
            type: String,
            required: false,
            default: ''
        },
        showTitle: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false
        },
        btnColor: {
            type: String,
            required: false,
            default: '#F4514C'
        },
        showPhoneBtn: {
            type: Boolean,
            required: false
        },
        patientId:{
            type: String,
            required: false
        },
        cachePrefix:{
            type: String,
            required: false,
            default: ''
        },
        //表单拥有者的userId
        ownerUserId: {
            type: String,
            required: false,
            default: ''
        },
    },
    //组件初始化,未完全创建阶段
    beforeCreate() {
    },
    //组件创建后，但还未挂载
    created() {
        // this.setupProperties()
        this.fetchData()
        this.fetchRepeatToken()
    },
    computed: {
        surveyPropertyType() {
            return this.$constant.surveyPropertyType
        },
        currentOwnerUserId() {
            return this.ownerUserId?this.ownerUserId:(this.surveyData?this.surveyData.ownerUserId:'')
        }
    },
    watch: {},
    deactivated() {
        console.log('deactivated')
        this.doWithCachedData()
    },
    //销毁前
    beforeDestroy() {
        console.log('beforeDestroy')
        this.doWithCachedData()
        this.$globalEvent.$off('selectChanged')
    },
    //销毁后
    destroy() {
    },
    methods: {
        setupProperties() {
            this.surveyPreProperties = []
            this.surveyNormalProperties = []
            for (const property of this.survey.properties) {
                this.updateProperty(property)
                if (property.preStep) {
                    this.surveyPreProperties.push(property)
                } else {
                    this.surveyNormalProperties.push(property)
                }
            }
            this.showPopup = this.surveyPreProperties && this.surveyPreProperties.length > 0

            let allSurveyProperties = []
            Array.prototype.push.apply(allSurveyProperties, this.surveyNormalProperties)
            Array.prototype.push.apply(allSurveyProperties, this.surveyPreProperties)
            surveyUtil.setCurrentSurveyPropertyList(allSurveyProperties)
            surveyUtil.setCurrentSurvey(this.survey)
            surveyUtil.setCurrentSurveyOwnerUserId(this.currentOwnerUserId)
            surveyUtil.setCurrentSurveyDataId(this.surveyDataId)
        },
        updateProperty(property) {
            const propertyData = this.findPropertyDataByPropertyId(property.id)
            property.propertyData = propertyData
        },
        findPropertyDataByPropertyId(propertyId) {
            if (this.surveyData && this.surveyData.propertyDataList) {
                for (const propertyData of this.surveyData.propertyDataList) {
                    if (propertyData.propertyId === propertyId) {
                        return propertyData
                    }
                }
            }
            return {
                propertyId: propertyId,
                content: ''
            }
        },
        fetchData() {
            if (this.surveyDataId) {
                this.$httpUtil.get(this.$urlConstant.survey.surveyDataGetPut + this.surveyDataId,res => {
                    this.surveyData = res.data
                    this.setupProperties()
                },this)
            } else {
                setTimeout(() => {
                    this.surveyData = this.getCachedData()
                    this.setupProperties()
                }, 100)
            }
        },
        fetchRepeatToken() {
            this.$httpUtil.get(this.$urlConstant.survey.repeatTokenGet,res => {
                this.repeatToken = res.data
            },this)
        },
        preClick() {
            for (const property of this.surveyPreProperties) {
                const result = this.checkProperty(property)
                if (!result) {
                    return
                }
            }
            this.showPopup = false
        },
        saveData() {
            for (const property of this.surveyPreProperties) {
                const result = this.checkProperty(property)
                if (!result) {
                    return
                }
            }
            for (const property of this.surveyNormalProperties) {
                const result = this.checkProperty(property)
                if (!result) {
                    return
                }
            }

            this.prepareSurveyDataBeforeSave()
            this.surveyData.repeatToken = this.repeatToken
            this.$emit('onSaved', {surveyData:this.surveyData,survey: this.survey})
        },
        prepareSurveyDataBeforeSave() {
            this.surveyData.surveyId = this.survey.id
            let surveyDataProperties = []
            this.surveyPreProperties.forEach(property => surveyDataProperties.push(property.propertyData))
            this.surveyNormalProperties.forEach(property => surveyDataProperties.push(property.propertyData))
            this.surveyData.propertyDataList = surveyDataProperties
        },
        checkProperty(property) {
            if (!property.required) {
                return true
            }
            if (!property.propertyData) {
                this.$message.warning('请输入 ' + property.name)
                return false
            }
            if (property.propertyData.hidden) {
                return true
            }
            const singleSelectIsEmpty = property.type === this.$constant.surveyPropertyType.singleSelect && StringUtil
                .isEmpty(property.propertyData.single)
            const multiSelectIsEmpty = property.type === this.$constant.surveyPropertyType.multiSelect && (!property
                .propertyData.multi || property.propertyData.multi.length <= 0)
            const textIsEmpty = property.type === this.$constant.surveyPropertyType.text && StringUtil.isEmpty(property
                .propertyData.content)
            const textareaIsEmpty = property.type === this.$constant.surveyPropertyType.textarea && StringUtil.isEmpty(
                property.propertyData.content)
            const imageInputIsEmpty = property.type === this.$constant.surveyPropertyType.image && (!property
                .propertyData.imgUrls || property.propertyData.imgUrls.length <= 0)
            const dateIsEmpty = property.type === this.$constant.surveyPropertyType.date && StringUtil.isEmpty(property
                .propertyData.content)
            if (singleSelectIsEmpty || multiSelectIsEmpty || textIsEmpty || textareaIsEmpty || imageInputIsEmpty || dateIsEmpty) {
                this.$message.warning('请输入 ' + property.name)
                return false
            }
            //电话号码验证
            if (property.type === this.$constant.surveyPropertyType.text) {
                if (property.displayType === this.$constant.surveyPropertyInputDisplayType.mobile) {
                    let mobile = property.propertyData.content
                    while (mobile.endsWith('#')) {
                      mobile = mobile.substring(0, mobile.length - 1)
                    }
                    const isMobileAndValid = !isNaN(mobile) && mobile.startsWith(1) && mobile.length === 11
                    const isCommonAndValid = !isNaN(mobile) && mobile.startsWith(0) && (mobile.length === 11 || mobile.length === 12)
                    console.log(!isNaN(mobile))
                    console.log('isMobileAndValid:'+isMobileAndValid)
                    console.log('isCommonAndValid:'+isCommonAndValid)
                    const result = isMobileAndValid || isCommonAndValid
                    console.log('result:' + result)
                    if (!result) {
                        this.$message.warning(property.name + ' 格式不正确')
                        return false
                    }
                }
            }
            return true
        },
        getCacheKey() {
            return this.cachePrefix ? (this.cachePrefix + '_' + this.survey.id) : this.survey.id
        },
        //离开页面或页面销毁的时候执行
        doWithCachedData() {
            //保存成功信号-->删除缓存
            if (this.doCleanCachedData) {
                this.$sess.remove(this.getCacheKey())
            }else {
                this.cacheCurrentData()
            }
        },
        //新增的情况下缓存页面数据
        cacheCurrentData() {
            if (!this.surveyDataId) {
                this.prepareSurveyDataBeforeSave()
                this.$sess.set(this.getCacheKey(), this.surveyData)
            }
        },
        getCachedData() {
            let cachedData = this.$sess.get(this.getCacheKey())
            console.log(`cachedData:${cachedData}`)
            if (!cachedData) {
                cachedData = {}
            }
            cachedData.id = undefined
            return cachedData
        },
        cleanCachedData() {
            console.log('cleanCachedData')
            this.doCleanCachedData = true
        },
        makePhoneCall() {
            this.$emit('make-phone-call')
        }
    }
}
</script>

<style scoped>
.sc-content {
    display: flex;
}
.sc-patient {
    margin: 42px 0 0;
    background: #656d72;
    box-shadow: -6px -8px 12px 0 #fff, 6px 8px 12px 0 #adc1dc;
    width: 180px;
}
.survey_popup {
    background: rgba(0, 0, 0, 0.50);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.survey_popup_wrapper {
    background-color: #FFFFFF;
    width: calc(100vw - 100px);
    min-height: 300px;
    border-radius: 4px;
    padding: 50px 0 20px 0;
}

.survey_popup_line {
    margin: 12px 19px;
}

.pre_button_wrapper {
    display: flex;
    justify-content: center;
    margin: 60px 50px 0 50px;
}

.pre_button {
    height: 35px;
    line-height: 35px;

}

.save_button {
    height: 50px;
    line-height: 50px;
}

.sc_button {
    width: 100%;
    background: #F4514C;
    border-radius: 10px;
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
}

.title_wrapper {
    padding: 10px;
}

.title_label {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
}

.title_desc {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #666666;
    letter-spacing: 0;
    line-height: 25px;
}

.survey_content {
    /*margin: 30px;*/
    /*padding-bottom: 80px;*/
}


</style>
