<template>
	<div>
		<YtImageInputSwiper v-if="property.displayType === surveyPropertyImageDisplayType.swiper"
			:property="property" :value.sync="imgSrcList" :propertyIndex="propertyIndex"></YtImageInputSwiper>
		<YtImageInputDefault v-else :property="property"
			:value.sync="imgSrcList" :propertyIndex="propertyIndex"></YtImageInputDefault>
	</div>
</template>

<script>
	import YtImageInputDefault from './YtImageInputDefault.vue'
	import YtImageInputSwiper from './YtImageInputSwiper.vue'
	export default {
		name: '',
		components: {
			YtImageInputDefault,
			YtImageInputSwiper
		},
		data() {
			return {
				imgSrcList: this.value?this.value:[],
			}
		},
		props: {
			property: {
				type: Object,
				required: true
			},
			value: {
				required: false
			},
			propertyIndex: {
				type: Number,
				required: false
			}
		},
		computed: {
			surveyPropertyImageDisplayType() {
				return this.$constant.surveyPropertyImageDisplayType
			},
		},
		watch: {
			value(val) {
				this.imgSrcList = val?val:[];
			},
			imgSrcList(val) {
				this.$emit('update:value', val)
			}
		}
	}
</script>

<style scoped>
	
</style>
