<template>
	<div>
		<div class="ta_label">{{property.name}}</div>
		<div class="ta_desc">{{property.description?property.description:''}}</div>
		<div class="ta_ta_wrapper">
			<textarea class="ta_ta" v-model="val" :maxlength="property.max?property.max:500"></textarea>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				val: this.value,
			}
		},
		props: {
			property: {
				type: Object,
				required: true
			},
			value: {
				required: false
			},
			propertyIndex:{
				type: Number,
				required: true
			}
		},
		watch: {
			value(val) {
				this.val = val;
			},
			val(val) {
				this.$emit('update:value', val)
			}
		}
	}
</script>

<style scoped>
.ta_label {
	font-family: PingFangSC-Regular;
	font-size: 16px;
	color: #000000;
	letter-spacing: 0;
	line-height: 25px;
}
.ta_desc {
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #666666;
	letter-spacing: 0;
	line-height: 25px;
}
.ta_ta_wrapper {
	background: #FFFFFF;
	border: 1px solid #979797;
	border-radius: 4px;
}
.ta_ta {
	width: calc(100% - 10px);
	padding: 5px;
}
</style>
