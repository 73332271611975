<template>
	<div class="ii_content" v-if="show">
        <div class="ii_title_view">
            <div class="ii_upload"></div>
            <div class="ii_label">{{property.name}}</div>
            <!--		<div class="ii_desc">{{property.description?property.description:''}}</div>-->
            <div class="ii_upload">
                <img @click="showDel=!showDel" src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/353518ac817a96fa68768cee7525270b.png"/>
                <el-upload
                        class="cover-upload"
                        :action="fileUpUrl"
                        :http-request="upFile"
                        multiple
                        :limit="maxCnt"
                        :show-file-list="false"
                        :before-upload="checkFile">
                    <img src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/de5107bbc0efbd55bd7d5adc68714391.png"/>
                </el-upload>
            </div>
        </div>
		<div class="ii_ii_wrapper">
            <el-carousel indicator-position="outside" :autoplay="false">
                <el-carousel-item v-for="(imgSrc,index) in imgSrcList" :key="index">
                    <div class="ii_image_wrapper">
                        <img class="ii_image" :src="imgSrc"/>
                        <img class="ii_image_del" v-if="showDel" @click="remove(index)" src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/353518ac817a96fa68768cee7525270b.png"/>
                    </div>
                </el-carousel-item>
            </el-carousel>
		</div>
	</div>
</template>

<script>
	import surveyUtil from "@/common/surveyUtil";

    export default {
		name: '',
		components: {},
		data() {
			return {
				imgSrcList: this.value?this.value:[],
				maxCnt: this.property.max?this.property.max:9,
				current: 0,
                showDel: false,
                show: !this.property.hidden
			}
		},
		props: {
			property: {
				type: Object,
				required: true
			},
			value: {
				required: false
			},
			propertyIndex: {
				type: Number,
				required: true
			}
		},
		watch: {
			value(val) {
				this.imgSrcList = val?val:[];
			},
			imgSrcList(val) {
				this.$emit('update:value', val)
			}
		},
        computed:{
            fileUpUrl: function () {
                return this.$urlConstant.cms.articleFilePost
            },
        },
        created() {
            this.$globalEvent.$on('selectChanged', (property)=>{
                // console.log('selectChanged on')
                setTimeout(()=>{
                    const show = surveyUtil.showProperty(this.property, property)
                    if (show === 1) {
                        this.show = true
                        this.property.propertyData.hidden = !this.show
                    }
                    if (show === 2) {
                        this.show = false
                        this.property.propertyData.hidden = !this.show
                    }
                },100)

            })
        },
		methods:{
			swiperChange(e) {
				this.current = e.detail.current
			},
            upFile(req) {
                console.log(req)
                let file = req.file
                let formData = new FormData();
                formData.append('name', file.name);
                formData.append('file', file);
                this.$httpUtil.postForm(this.$urlConstant.survey.surveyUpfile, formData, (responseData) => {
                    console.log('responseData:' + JSON.stringify(responseData));
                    this.imgSrcList.push(responseData.data.fullName)
                    this.$message({
                        message: '上传成功',
                        type: 'success'
                    });

                }, this);
			},
			remove(index) {
				this.imgSrcList.splice(index, 1)
			},
            checkFile(file) {
                console.log(file.size)
                if (file.size > 5 * 1024 * 1024) {
                    this.$message.error("文件大小不能超过5m")
                    return false
                }
                return true
            }
		}
	}
</script>

<style scoped>
	.ii_content {
        background: #FFFFFF;
	}
    .ii_title_view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 25px;
    }
	.ii_label {
		line-height: 25px;
        font-family: Helvetica;
        font-size: 15px;
        color: #556BE5;
        letter-spacing: 1.12px;
        text-align: center;
	}
	
	.ii_upload {
		text-align: right;
		padding-right: 28px;
        width: 100px;
        display: flex;
        flex-direction: row;
	}
	.ii_upload img {
		width: 23px;
		height: 23px;
        padding-left: 20px;
        cursor: pointer;
	}

	.ii_ii_wrapper {
		
	}
	.ii_image_wrapper{
        width: 100%;
        height: 100%;
		position: relative;
	}
	.ii_image {
        width: 100%;
        height: 100%;
        object-fit: contain;
	}

    .ii_image_del {
        width: 30px;
        height: 30px;
        position: absolute;
        cursor: pointer;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
    }

	
	.cross {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: rgb(0,0,0,.3);
	}
	
	.cross::before,
	.cross::after {
        content: '';
        width: 2px;
        height: 15px;
        background: #FFFFFF;
        position: absolute;
        right: 9px;
		top:3px
	}
	
	.cross::before {
        transform: rotate(45deg);
	}
	
	.cross::after {
        transform: rotate(-45deg);
	}
</style>
