<template>
	<div>
    </div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				
			}
		},
		props: {
		},
        //组件初始化,未完全创建阶段
		beforeCreate() {},
        //组件创建后，但还未挂载
        created() {},
        //渲染后待挂载
        beforeMount(){},
        //组件挂载到页面OK,可用 vm.$el 访问
        mounted() {},
        //再次渲染前
        beforeUpdate() {},
        //再次渲染后
        updated(){},
        //当前组件被激活：显示
        activated() {},
        //当前组件隐藏
        deactivated() {},
        //销毁前
        beforeDestroy(){},
        //销毁后
        destroy() {},
		methods: {},
        //让css深度选择生效
        options: { styleIsolation: 'shared' }
	}
</script>

<style scoped>
	
</style>
