<template>
	<div v-show="show">
		<div class="sd_wrapper">
			<div class="sd_label">{{property.name}}</div>
			<div class="sd_line"></div>
			<div class="sd_select">
				<SelectDatasourcePicker v-if="property.selectDatasource" :sourceKey="property.selectDatasource"
                                       :multiple="false" v-model="val" :property="property"></SelectDatasourcePicker>
                <el-select v-else v-model="val" placeholder="请选择" :disabled="property.ro || property.propertyData.immutable" @change="doChange">
                    <el-option
                            v-for="(item) in property.options"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
			</div>
		</div>
		<div v-if="showOtherInput" class="sd_wrapper">
            <el-input class="input_inner_input" v-model="otherVal" type="text"></el-input>
		</div>
	</div>
</template>

<script>
	import SelectDatasourcePicker from './SelectDatasourcePicker.vue'
    import surveyUtil from "@/common/surveyUtil";
	export default {
		name: '',
		components: {
			SelectDatasourcePicker
		},
		data() {
			return {
				val: this.value,
				showOtherInput: false,
				otherVal: this.otherValue,
                show: !this.property.hidden
			}
		},
		props: {
			property: {
				type: Object,
				required: true
			},
			value: {
				required: false
			},
			otherValue: {
				required: false
			},
			propertyIndex: {
				type: Number,
				required: true
			}
		},
		watch: {
			value(optionId) {
				console.log(`watch value optionId:${optionId}`)
				this.val = optionId
			},
            val(optionId) {
                console.log(`watch val optionId:${optionId}`)
                this.$emit('update:value', optionId)
                this.$globalEvent.$emit('selectChanged', this.property)
                this.doDataChangedAction()
            },
			otherValue(val) {
				this.otherVal = val
			},
			otherVal(val) {
				// console.log(val)
				this.$emit('update:otherValue', val)
			}
		},
        mounted() {
            if (this.value){
                console.log('emit value:' + this.value)
                this.$globalEvent.$emit('selectChanged', this.property)
            }
            this.doDataChangedAction()
        },
        created() {
            this.doSetup(this.value)
            this.$globalEvent.$on('selectChanged', (property)=>{
                console.log('selectChanged on in YtSingleSelectDrop')
                setTimeout(()=>{
                    const show = surveyUtil.showProperty(this.property, property)
                    console.log('show is '+show)
                    if (show === 1) {
                        this.show = true
                        this.property.propertyData.hidden = !this.show
                    }
                    if (show === 2) {
                        this.show = false
                        this.property.propertyData.hidden = !this.show
                    }
                },100)

            })
        },
		methods: {
            doChange() {
				const option = this.findOptionByOptionId(this.val)
				// this.$emit('update:value', option.id)
				this.showOtherInput = option.other
			},
            findOptionByOptionId(optionId) {
                if (this.property.options) {
                    const opt = this.property.options.find(option => option.id === optionId)
                    return opt
                }
                return undefined
            },
            doSetup(optionId) {
				if (!optionId)  {
					if (this.property.parsedDefaultValue) {
						const option = this.findOptionByOptionId(this.property.parsedDefaultValue)
                        this.val = option.id
						this.$emit('update:value', option.id)
					}
				}
				if(this.val) {
                    const option = this.findOptionByOptionId(this.val)
                    if (option)
                        this.showOtherInput = option.other
				}
			},
            doDataChangedAction() {
                setTimeout(()=>{
                    surveyUtil.doDataChangedAction(this.property, this.val)
                },100)

            }
		}
	}
</script>

<style scoped>
	.sd_wrapper {
		display: flex;
		background: #FFFFFF;
		border-radius: 4px;
		width: 100%;
		min-height: 30px;
		font-size: 12px;
        color: #1B243F;
        letter-spacing: 0.89px;
		align-items: center;
	}

	.sd_label {
		padding-left: 10px;
		/*width: 87px;*/
		/*min-width: 87px;*/
	}

	.sd_line {
		height: 23px;
		width: 1px;
		background: #656D72;
		margin: 0 9px;
		min-width: 1px;
	}

	.sd_select {
		display: flex;
		align-items: center;
	}

	.sd_select,
	.sd_picker,
	.sd_input {
		min-height: 30px;
		line-height: 30px;
		flex: 1;
	}

	.sd_readonly {
		color: lightgray;
	}
    .sd_select >>> .el-select {
        width: 100%;
    }
    .sd_select >>> .el-input__inner {
        border: unset;
        height: 30px;
    }
</style>
