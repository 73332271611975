<template>
    <div v-if="tip" class="yt-tip">
        {{tip}}
    </div>
</template>

<script>
export default {
    name: "TipView",
    props:{
        tip: String
    }
}
</script>

<style scoped>
.yt-tip {
    font-family: Helvetica;
    font-size: 15px;
    color: #556BE5;
    letter-spacing: 1.12px;
    text-align: left;
    margin-top: 5px;
}
</style>