<template>
    <div class="sd_wrapper" v-if="show">
        <div class="sd_label">{{property.name}}</div>
        <div class="sd_line"></div>
        <div class="sd_select">
            <el-radio-group class="ss_radio_group" v-model="val" :disabled="property.ro || property.propertyData.immutable" @input="doChange">
                <el-radio class="ss_radio_label" v-for="(item, index) in property.options" :key="index"
                          :label="item.id">{{item.name}}</el-radio>
            </el-radio-group>
        </div>
    </div>
</template>

<script>
import surveyUtil from "@/common/surveyUtil";

export default {
    name: "YtSingleSelectBoolean",
    components: {},
    data() {
        return {
            val: this.value,
            show: !this.property.hidden
        }
    },
    props: {
        property: {
            type: Object,
            required: true
        },
        value: {
            required: false
        },
        propertyIndex: {
            type: Number,
            required: true
        },
        classPrefix: {
            type: String,
            required: false,
            default: ''
        }
    },
    watch: {
        value(val) {
            this.val = val;
        },
        val(val) {
            this.$emit('update:value', val)
            this.$globalEvent.$emit('selectChanged', this.property)
        }
    },
    mounted() {
        if (this.value) {
            this.$globalEvent.$emit('selectChanged', this.property)
        }
        this.doDataChangedAction()
    },
    created() {
        this.doSetup(this.value)
        this.$globalEvent.$on('selectChanged', (property)=>{
            // console.log('selectChanged on')
            setTimeout(()=>{
                const show = surveyUtil.showProperty(this.property, property)
                if (show === 1) {
                    this.show = true
                    this.property.propertyData.hidden = !this.show
                }
                if (show === 2) {
                    this.show = false
                    this.property.propertyData.hidden = !this.show
                }
            },100)

        })
    },
    methods: {
        doSetup(optionId) {
            if (!optionId)  {
                if (this.property.parsedDefaultValue) {
                    const option = this.findOptionByOptionId(this.property.parsedDefaultValue)
                    this.val = option.id
                    this.$emit('update:value', option.id)
                }
            }
        },
        findOptionByOptionId(optionId) {
            if (this.property.options) {
                const opt = this.property.options.find(option => option.id === optionId)
                return opt
            }
            return undefined
        },
        doChange(content) {
            this.val = content
            this.doDataChangedAction()
        },
        doDataChangedAction() {
            surveyUtil.doDataChangedAction(this.property, this.val)
        }
    }
}
</script>

<style scoped>
.sd_wrapper {
    display: flex;
    background: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    height: 30px;
    font-size: 12px;
    color: #1B243F;
    letter-spacing: 0.89px;
    align-items: center;
}

.sd_label {
    padding-left: 10px;
    /* width: 87px; */
    /* min-width: 87px; */
    font-size: 12px;
}

.sd_line {
    height: 23px;
    width: 1px;
    background: #878F95;
    margin: 0 9px;
    min-width: 1px;
}

.sd_select {
    flex: 1;
}
.ss_radio_group {
    display: flex;
    justify-content: flex-end;
}
.ss_radio_label {
    display: flex;
    padding: 5px 10px;
}
.ss_radio_v {
    margin-right: 10px;
}
</style>