<template>
	<div class="sd_wrapper" v-if="show">
		<div class="sd_label">{{property.name}}</div>
		<div class="sd_line"></div>
		<div class="sd_select">
            <el-date-picker
                    v-model="val"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    :disabled="property.ro || property.propertyData.immutable"
                    @change="doChange"
                    :picker-options="pickerOptions">
            </el-date-picker>
		</div>
	</div>
</template>

<script>
	import surveyUtil from "@/common/surveyUtil";
    const nowDate = new Date().setHours(0, 0, 0, 0)
    export default {
		name: 'YtDateSelect',
		components: {
		},
		data() {
			return {
				val: this.value,
                show: !this.property.hidden,
                pickerOptions:{
                    disabledDate: (currentDate)=>{
                        if (this.property.limitDateStart)
                            return nowDate > currentDate
                        else if (this.property.limitDateEnd)
                            return nowDate < currentDate
                        else
                            return false
                    }
                }
			}
		},
		props: {
			property: {
				type: Object,
				required: true
			},
			value: {
				required: false
			},
			propertyIndex:{
				type: Number,
				required: true
			}
		},
        watch: {
            value(val) {
                this.setupVal(val)
            },
            val(val) {
                this.$emit('update:value', val)
            },
            // 'property.propertyData.content': function(newVal, oldVal){
            //     console.log('property.propertyData.content ==============' + newVal + oldVal)
            // },
            // propertyContent(val) {
            //     console.log('property.propertyData.content ==============')
            //     this.setupVal(val)
            // },
            // property: {
            //     handler(val){
            //         console.log('property ==============' + val.id + ',' + val.propertyData.content)
            //     },
            //     deep: true
            // }
        },
		computed: {
			fields() {
				if(this.property.displayType === this.$constant.surveyPropertyDateDisplayType.ym) {
					return 'month'
				} else if(this.property.displayType === this.$constant.surveyPropertyDateDisplayType.y) {
					return 'year'
				}
				return 'day'
			},
            propertyContent() {
                return this.property.propertyData.content
            }
		},
        mounted() {
            this.doDataChangedAction()
        },
        created() {
            this.setupVal(this.value)
            this.$globalEvent.$on('selectChanged', (property)=>{
                // console.log('selectChanged on')
                setTimeout(()=>{
                    const show = surveyUtil.showProperty(this.property, property)
                    if (show === 1) {
                        this.show = true
                        this.property.propertyData.hidden = !this.show
                    }
                    if (show === 2) {
                        this.show = false
                        this.property.propertyData.hidden = !this.show
                    }
                },100)
            })
        },
		methods: {
			setupVal(value) {
				//只读的情况,取默认值
				if (this.property.ro) {
					if(this.property.parsedDefaultValue) {
						this.val = this.property.parsedDefaultValue
						this.$emit('update:value', this.property.parsedDefaultValue)
					}else {
						this.val = value
					}
				}else {
					if(value) {
						this.val = value
					}else {
						if(this.property.parsedDefaultValue) {
							this.val = this.property.parsedDefaultValue
							this.$emit('update:value', this.property.parsedDefaultValue)
						}else {
							this.val = ''
						}
					}
				}
			},
            doChange(content) {
                this.val = content
                this.doDataChangedAction()
            },
            doDataChangedAction() {
                surveyUtil.doDataChangedAction(this.property, this.val)
            }
		},
		//让css深度选择生效
		options: { styleIsolation: 'shared' }
	}
</script>

<style scoped>
	.sd_wrapper {
		display: flex;
		background: #FFFFFF;
		border-radius: 4px;
		width: 100%;
		height: 30px;
		font-size: 12px;
        color: #1B243F;
        letter-spacing: 0.89px;
		align-items: center;
	}
	
	.sd_label {
		padding-left: 10px;
		/*width: 87px;*/
	}
	
	.sd_line {
		height: 23px;
		width: 1px;
		background: #656D72;
		margin: 0 9px;
	}
	
	.sd_select{
		height: 30px;
		line-height: 30px;
		flex: 1;
		text-align: left;
	}
    .sd_select >>> .el-date-editor {
        width: 100%;
    }
    .sd_select >>> .el-input__inner {
        height: 30px;
        border: unset;
    }
    .sd_select >>> .el-input__icon {
        line-height: 30px;
    }
</style>
