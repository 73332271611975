<!--
TODO 逻辑,联动,界面都未实现
-->
<template>
	<div>
		<div class="ii_label">{{property.name}}</div>
		<div class="ii_desc">{{property.description?property.description:''}}</div>
		<div class="ii_ii_wrapper">
			<div class="ii_image_wrapper" v-for="(imgSrc,index) in imgSrcList" :key="index"  @click="updateImage(index)">
				<el-image :src="imgSrc"></el-image>
				<div class="cross" @click.stop="remove(index)"></div>
			</div>
			<div class="ii_image_wrapper" v-if="imgSrcList.length < maxCnt" @click="addImage">
				<el-image src="../../../../static/img/survey/survey_add_img.png"></el-image>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				imgSrcList: this.value?this.value:[],
				maxCnt: this.property.max?this.property.max:9
			}
		},
		props: {
			property: {
				type: Object,
				required: true
			},
			value: {
				required: false
			},
			propertyIndex: {
				type: Number,
				required: true
			}
		},
		watch: {
			value(val) {
				this.imgSrcList = val?val:[];
			},
			imgSrcList(val) {
				this.$emit('update:value', val)
			}
		},
		methods:{
			updateImage(index) {
				this.selectImage(index)
			},
			addImage() {
				this.selectImage(-1)
			},
			selectImage(index) {
				console.log(index)
				// uni.chooseImage({
				// 	count: index === -1 ? (this.maxCnt - this.imgSrcList.length):1,
				// 	// count: 1,
				// 	sizeType: ['original', 'compressed'], //可以指定是原图还是压缩图，默认二者都有
				// 	sourceType: ['album', 'camera'],
				// 	success: res => {
				// 		const tempFiles = res.tempFiles
				// 		// const src = tempFiles[0].path
				// 		// this.fetchBlob(src)
				// 		if(index === -1) { //新增
				// 			this.uploadImages(tempFiles)
				// 		}else { //修改
				// 			this.uploadImage(tempFiles[0],index)
				// 		}
				// 	},
				// 	fail: e => {
				// 		console.log(e)
				// 	},
				// 	complete: () => {
				// 		console.log('complete')
				// 	}
				// })
			},
			async fetchBlob(url) {
				let blob = await fetch(url).then(r => r.blob())
				console.log(blob)
			},
			//多图片上传(新增)
			uploadImages(imageFileList) {
				for(const imageFile of imageFileList) {
					this.uploadImage(imageFile, -1)
				}
			},
			uploadImage(imageFile,currentIndex) {
				console.log('uplaod imageFile:' + JSON.stringify(imageFile))
				const formData = {
					name: imageFile.name
				}
				this.$httpUtil
					.uploadFile(this.$urlConstant.survey.surveyUpfile, imageFile, formData)
					.then((res) => {
						console.log(res)
						console.log(currentIndex)
						if(currentIndex >= 0) {
							// this.imgSrcList[currentIndex] = res.data.fullName
							this.$set(this.imgSrcList,currentIndex,res.data.fullName)
						}else {
							this.imgSrcList.push(res.data.fullName)
						}
					})
			},
			remove(index) {
				this.imgSrcList.splice(index, 1)
			}
		}
	}
</script>

<style scoped>
	.ii_label {
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #000000;
		letter-spacing: 0;
		line-height: 25px;
	}

	.ii_desc {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #666666;
		letter-spacing: 0;
		line-height: 25px;
	}

	.ii_ii_wrapper {
		display: flex;
		flex-wrap: wrap;
		margin-left: 5px;
		
	}
	.ii_image_wrapper{
		width: calc((100vw - 100px)/3);
		height: calc((100vw - 100px)/3);
		margin: 10px 15px 10px 0;
		position: relative;
	}
	.ii_image_wrapper image {
		width: 100%;
		height: 100%;
	}
	
	.cross {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: rgb(0, 0, 0, .3);
	}
	
	.cross::before,
	.cross::after {
        content: '';
        width: 2px;
        height: 15px;
        background: #FFFFFF;
        position: absolute;
        right: 9px;
		top:3px
	}
	
	.cross::before {
        transform: rotate(45deg);
	}
	
	.cross::after {
        transform: rotate(-45deg);
	}
</style>
