<template>
    <el-dialog class="mp-dialog"
               :visible.sync="showDialog"
               :show-close="false" title="预览">
        <div class="mp-main">
            <div class="mp-container">
                <div class="pdc-panel-inner">
                    <template v-for="(property,index) in survey.properties">
                        <div :key="index" class="pdc-panel-line" v-if="hidePropertyData(property.propertyData)">
                            <YtImageDisplay v-if="property.type === surveyPropertyType.image"
                                            :property="property"></YtImageDisplay>
                            <YtFileDisplay v-if="property.type === surveyPropertyType.file"
                                           :property="property"></YtFileDisplay>
                            <YtDefaultDisplay
                                    v-if="property.type !== surveyPropertyType.image && property.type !== surveyPropertyType.file"
                                    :property="property"></YtDefaultDisplay>
                        </div>
                    </template>
                </div>
                <div class="mp-tips">提交后不能修改,请确保内容正确</div>
                <div class="mp-buttons">
                    <el-button  v-on:click="showDialog = false">取消</el-button>
                    <el-button type="primary" v-on:click="doSubmit">提交</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import YtImageDisplay from "@/components/survey/components/properties/imageInput/YtImageDisplay";
import YtFileDisplay from "@/components/survey/components/properties/fileInput/YtFileDisplay";
import YtDefaultDisplay from "@/components/survey/components/properties/YtDefaultDisplay";
export default {
    name: "SurveyPreviewDialog",
    components: {YtDefaultDisplay, YtFileDisplay, YtImageDisplay},
    data() {
        return {
            showDialog: this.show,
        }
    },
    props:{
        survey: {
            type: Object,
            required: true
        },
        show: {
            type: Boolean,
            required: true
        },
    },
    watch: {
        show(val) {
            this.showDialog = val;
        },
        showDialog(val) {
            this.$emit('update:show', val)
        }
    },
    computed:{
        surveyPropertyType() {
            return this.$constant.surveyPropertyType
        },
    },
    methods:{
        doSubmit() {
            this.$emit('confirm')
            this.showDialog = false
        },
        hidePropertyData(propertyData) {
            return propertyData && !propertyData.hidden
        }
    }
}
</script>

<style scoped>
.mp-dialog >>> .el-dialog{
    max-width: 480px;
}
.mp-buttons {
    margin-top: 50px;
}
.mp-tips {
    margin: 20px 0;
    font-family: Helvetica;
    font-size: 15px;
    color: #E42B13;
    letter-spacing: 1.12px;
}
.pdc-panel-inner {
    padding: 20px;
}
.pdc-panel-line {
    padding: 5px 0;
}
</style>